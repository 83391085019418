<template>
  <v-row class="match-height">
    <!-- horizontal -->
    <v-col cols="12" md="12">
      <v-data-table
        :headers="headers"
        :items="desserts"
        :options.sync="options"
        :server-items-length="totalDesserts"
        :loading="loading"
        dense
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Jenis Simpanan</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="editedItem.besar_simpanan" label="Besar Simpanan *"></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" @click="close"> Batal </v-btn>
                  <v-btn color="primary" @click="save()"> Simpan </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>

        <template v-slot:item.no="{ index }">
          <span>{{ index + 1 }}</span>
        </template>

        <template v-slot:item.besar_simpanan="{ item }">
          <span>{{ new Intl.NumberFormat(['id']).format(item.besar_simpanan) }}</span>
        </template>

        <template v-slot:item.is_fixed="{ item }">
          <span v-if="item.is_fixed === '1'">Tidak</span>
          <span v-else>Ya</span>
        </template>

        <template v-slot:item.aksi="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)"> {{ icons.mdiPencil }} </v-icon>
        </template>

        <template v-slot:no-data>
          <v-btn color="primary" @click="initialize"> Reload</v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    return {
      icons: {
        mdiPencil,
      },
    }
  },
  data: () => ({
    // datatable

    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'No', value: 'no', sortable: false },
      { text: 'Jenis Upah', value: 'jenis_upah', sortable: true },
      { text: 'Nama', value: 'nama', sortable: false },
      { text: 'Besar Simpanan', value: 'besar_simpanan', sortable: false, align: 'right' },
      { text: 'Bisa Diubah', value: 'is_fixed', sortable: false },
      { text: 'Aksi', value: 'aksi', sortable: false },
    ],
    totalDesserts: 0,
    desserts: [],
    loading: true,
    options: {},
    editedIndex: -1,
    editedItem: {
      id: 'id',
      besar_simpanan: '',
    },
    defaultItem: {
      id: 'id',
      besar_simpanan: '',
    },
    search: '',
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Jenis Simpanan' : 'Edit Jenis Simpanan'
    },
    token() {
      return this.$store.getters.token
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },

  created() {
    // this.initialize()
  },

  methods: {
    // initialize() {
    // },
    getDataFromApi() {
      this.loading = true

      // const { page, itemsPerPage } = this.options

      const headers = {
        Authorization: this.token,
      }
      axios
        .get(`${apiRoot}/api/JenisSimpanan/get`, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.desserts = response.data.data
            this.totalDesserts = response.data.total
            this.loading = false
          } else {
            alert(response.data.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        // Object.assign(this.desserts[this.editedIndex], this.editedItem)

        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.editedItem.id)
        form.append('besar_simpanan', this.editedItem.besar_simpanan)
        axios
          .post(`${apiRoot}/api/JenisSimpanan/update`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.search = ''
              this.options.page = 1
              this.getDataFromApi()
              this.close()
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
      }
    },
  },
}
</script>
