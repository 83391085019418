var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Jenis Simpanan")]),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Besar Simpanan *"},model:{value:(_vm.editedItem.besar_simpanan),callback:function ($$v) {_vm.$set(_vm.editedItem, "besar_simpanan", $$v)},expression:"editedItem.besar_simpanan"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.close}},[_vm._v(" Batal ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)]},proxy:true},{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(index + 1))])]}},{key:"item.besar_simpanan",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Intl.NumberFormat(['id']).format(item.besar_simpanan)))])]}},{key:"item.is_fixed",fn:function(ref){
var item = ref.item;
return [(item.is_fixed === '1')?_c('span',[_vm._v("Tidak")]):_c('span',[_vm._v("Ya")])]}},{key:"item.aksi",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" Reload")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }